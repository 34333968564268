<template>
  <div class="table-component smi">
    <div class="table-vidjet">
      <table>
        <thead>
          <tr>
            <th>Новость</th>
            <th>Дата</th>
            <th>Страна</th>
          </tr>
        </thead>
        <tbody>
          <tr class="single-string" v-for="item in options" :key="item.id" @dblclick="viewCard(item.id)">
            <td class="single-string__text">
              <a :href="item.url" target="_blank">{{ item.brief }}</a>
            </td>
            <td class="single-string__date" :title="$momentFormat(item.datePublish, 'DD.MM.YYYY')">
              <span class="table-component__yyyy">{{ $momentFormat(item.datePublish, 'DD.MM.YYYY') }}</span>
            </td>
            <td>{{ item.countryNames }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-component__footer">
      <ButtonStock title="Все сообщения СМИ" @click="goTo" />
    </div>
  </div>
</template>

<script>
  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/widgetElement';
  export default {
    name: 'Widget11',
    components: {
      ButtonStock,
    },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    watch: {
      countryId: function (/*newValue*/) {
        this.loadItems();
      },
    },
    methods: {
      loadItems() {
        API.getMassMedia({ countryId: this.countryId }).then((response) => {
          this.options = response.data;
        });
      },
      goTo() {
        this.$router.push({ name: 'News', query: { tab: 3 } });
      },
      viewCard(id) {
        this.$router.push({ name: 'MassMediaCard', params: { id: id, action: 'view' } });
      },
    },
    data() {
      return {
        options: [],
      };
    },
    created() {
      this.loadItems();
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/components/widgets/inners/tables/tableWidgets.scss';
  .single-string {
    &__text {
      cursor: pointer;
      padding: 10px !important;
      a {
        color: black;
      }
      &:hover {
        a {
          color: blue;
        }
      }
    }
    &__date {
      width: 45px !important;
      padding-left: 0 !important;
    }
  }
</style>
